import React from 'react';
import { Breadcrumb, Button, Layout, Menu, Table } from "antd";
import AdminHeader from './header';
import './header.less';
const { Header, Content, Sider } = Layout;
const AdminLayout = ({ children, props }) => {

    return (<>
        <AdminHeader {...props} />
        <div className="divBody adminBodyDiv">
            {children}
        </div>
    </>)
};
export default AdminLayout;
