import React, { useState, useContext, useEffect } from "react";
import "./header.less";
import TopicsNavigation from "../../components/SubNavigations/TopicsNavigation";
import { Affix, Badge, Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { AuthContext } from "../../components/Context/AuthContext/AuthContext";
import { withRouter } from 'react-router-dom';
import { getMenue } from '../../../containers/auth/service';
import Gravatar from 'react-gravatar';
import { BlackList } from './service';
import InfiniteScroll from "react-infinite-scroller";
import socketIOClient from "socket.io-client";
import { BASE_URL } from "../../../apis/request";
import { getNotifications, ReadAll } from "../../components/Navigation/service";
import { CART } from "../../modules/Enums/cartEnums";
import Context from "../../../Context";
import logoCircle from "../../../containers/Homepage/images/circle_logo.png";
import toastr from "toastr";
import { UpdateCart } from "../../../containers/Products/service";
import { PERMISSIONS } from "../../modules/Enums/PermissionEnums";
// import {ShoppingCartOutlined} from '@ant-design/icons';

function isPermitted(permission) {
    let permissions = localStorage.getItem("PERMISSIONS");
    if (permissions) {
        if (permissions.indexOf(permission) > -1) {
            return true;
        }
        return false;
    }
    return false;
}

const AdminHeader = props => {
    const authContext = useContext(AuthContext);
    const { state, dispatch } = useContext(Context);
    const [collapsed, setCollapsed] = useState(true);
    const [notifications, SetNotifications] = useState([]);
    const [notificationsState, SetNotificationsState] = useState({
        items: 10,
        hasMoreItems: true
    });
    const [menue, setMenue] = useState([]);
    const [totalNotificatrions, setTotal] = useState(0);
    const [currentUrl, setCurrentUrl] = useState('');

    useEffect(() => {
        let url = props.location.pathname;
        setCurrentUrl(url);
        async function fetchData() {
            console.log("Page Reload");
            if (localStorage.getItem('TOKEN') != undefined) {
                GetNotifications(notificationsState.items)
                //const { data } = await getMenue(localStorage.getItem("ID"));

                const socket = socketIOClient(BASE_URL);
                socket.on("FromAPI", (message) => {
                    GetNotifications(notificationsState.items);
                });
            }
        }
        fetchData();
    }, []);

    async function GetNotifications(l) {
        const { data } = await getNotifications(l);
        SetNotifications(data.data);
        setTotal(data.total)
        console.log("Notification data", data);
    }
    function calculatTime(element) {
        const Time = new Date(element.createdAt);
        const currentTime = new Date();
        const diffrence = currentTime - Time;
        const seconds = diffrence / 1000;
        var minuts = 0;
        var hours = 0;
        var Days = 0;
        var weeks = 0;
        var returnTime = seconds.toFixed(0) + " seconds";
        if (seconds < 20) {
            returnTime = " just Now";
        }
        if (seconds > 60) {
            minuts = (seconds / 60);
            returnTime = minuts.toFixed(0) + " minutes";
        }
        if (minuts > 60) {
            hours = (minuts / 60);
            returnTime = hours.toFixed(0) + " hours";
        }
        if (hours > 24) {
            Days = (hours / 24);
            returnTime = Days.toFixed(0) + " days";
        }
        if (Days > 7) {
            weeks = (Days / 7);
            returnTime = weeks.toFixed(0) + " weeks";
        }
        return returnTime;
    }

    function loadMore() {
        if (notificationsState.items === 40) {
            SetNotificationsState({ ...notificationsState, hasMoreItems: false });
        }
        else {
            setTimeout(async () => {
                await GetNotifications(notificationsState.items + 10);
                SetNotificationsState({ ...notificationsState, items: notificationsState.items + 10 });
            }, 3000);
        }
    }

    async function onHover() {
        console.log("Hover");
        if (localStorage.getItem('TOKEN') != undefined) {
            const resp = await ReadAll(notificationsState.items);
            SetNotifications(resp.data.data);
            console.log("Notification data", resp.data);
            setTotal(resp.data.total)
        }
    }
    async function addQuantity(_id) {
        const {data} = await UpdateCart({type: 'add', quantity: 1}, _id);
        if(data != null && data.status == 'success'){
            toastr.success(data.message, "");
            dispatch({ type: CART.ADD_QUANTITY, _id });
        }else if(data != null && data.status == 'error'){
            toastr.error(data.message, '');
        }
    }

    function logout() {
        BlackList();
        authContext.logoutUser();
        props.history.push('/');
    }
    return (

        <Affix style={{ position: 'fixed', top: '0px', width: '100%', height: '60.2px', zIndex: '10' }}>
            <header id="header" className="text-uppercase" >
                <div className="container header-container">
                    <div className="row position-relative">
                        <div className="col">
                            <nav id="nav" className="navbar navbar-expand-lg text-md-right">
                                <Link to={"/"} className="logo-holder navbar-brand">
                                    <div className='logoMin pull-left'>
                                        <figure style={{margin:'0px'}}>
                                        <img src={logoCircle} alt="logo" className="img-fluid" style={{width:'60px'}}/>
                                        </figure>
                                        {/*<p className='label' style={{fontSize:'22px', top:'14.5px'}}>ZO</p>*/}
                                    </div>
                                </Link>
                                <div className="navbar-nav mr-auto">
                                    {/*<div className="flex-shrink-0">
                                        <Link to={"/"} className="logo-holder">
                                            {/*<img
                                                src='/images/logo2.png'
                                                alt="Zo"
                                                className="img-fluid"
                                                style={{ width: "50px" }}
                                            />*/}
                                            {/*<div className='logoMin pull-left'>
                                                <figure style={{margin:'0px'}}>
                                                <img src={logoCircle} alt="logo" className="img-fluid" style={{width:'60px'}}/>
                                                </figure>
                                                <p className='label' style={{fontSize:'24px', top:'12px'}}>ZO</p>
                                            </div>
                                        </Link>
                                    </div>*/}
                                    <button
                                        className={
                                            "navbar-toggler ml-auto first-button outline-none shadow-none"
                                        }
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#mainnavbarDropdown"
                                        aria-controls="mainnavbarDropdown"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                        onClick={() => {
                                            setCollapsed(!collapsed);
                                        }}
                                    >
                                        <div className="animated-icon1">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>
                                </div>
                                <div className="navbar-nav ml-auto">
                                    <div className="nav-link cart-link notification-admin" onMouseEnter={onHover}>
                                        <Badge count={notifications && totalNotificatrions} showZero={false} >
                                            <i className="fa fa-bell text-light"></i>
                                        </Badge>
                                        <div className="cart-popup" style={{ minWidth: '500px' }}>
                                            <div className="Notification-holder" >
                                                <InfiniteScroll
                                                    loadMore={() => loadMore()}
                                                    hasMore={notificationsState.hasMoreItems}
                                                    loader={<div className="loader"> Loading... </div>}
                                                    useWindow={false}
                                                >
                                                    {notifications && notifications.length > 0 ?
                                                        notifications.map(element => (
                                                            <>
                                                                <div className="row">
                                                                    <div style={{ textAlign: 'left' }} className="col-md-9" dangerouslySetInnerHTML={{ __html: element.message }} />
                                                                    <div className="col-md-3 time">
                                                                        {calculatTime(element)}
                                                                    </div>
                                                                </div>
                                                                <hr></hr>
                                                            </>
                                                        )) : ''}
                                                </InfiniteScroll>{" "}
                                            </div>{" "}
                                        </div>
                                    </div>
                                    <div className="nav-link user-link">
                                        <i className="fa">
                                            <Gravatar email="mathews.kyle@gmail.com" size={40} rating="pg" default="monsterid" className="rounded-circle CustomAvatar-image" />
                                        </i>
                                        <div className="user-popup">
                                            <div className="user-holder">
                                                <ul className="user-list text-center">
                                                    <li>
                                                        <Link to={"/profile"} style={{ 'cursor': 'pointer' }}>Profile</Link>
                                                    </li>
                                                    <li>
                                                        {!authContext.checkAuthentication() ? <Link to={"/login"}>Login</Link> : <div onClick={() => logout()} style={{ color: 'black', 'cursor': 'pointer' }}>Logout</div>}
                                                    </li>
                                                    {authContext.checkAuthentication() ? '' : <li>
                                                        <Link to={"/register"} style={{ 'cursor': 'pointer' }}>Signup</Link>
                                                    </li>}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        (collapsed ? "collapse p-3 p-md-0 d-none" : "") +
                                        "navbar-collapse justify-content-end p-3 p-md-0"
                                    }
                                    id="mainnavbarDropdown"
                                >
                                    <div className="d-inline-block d-lg-none">
                                        <Link to={"/"} className="logo-holder">
                                        <div className='logoMin pull-left mobile-logo'>
                                            <figure>
                                            <img src={logoCircle} alt="logo" className="img-fluid" style={{width:"60px !important"}}/>
                                            </figure>
                                            {/*<p className='label' style={{fontSize:"22px !important", top:"14.5px !important"}}>ZO</p>*/}
                                        </div>
                                        </Link>
                                    </div>
                                    <ul className="navbar-nav main-nav align-items-lg-center align-items-center mx-md-auto">
                                        {isPermitted(PERMISSIONS.DASHBOARD) ? <li className="nav-item"><Link to={'/admin/Dashboard'}>Dashboard</Link></li> : ''}
                                        {isPermitted(PERMISSIONS.PRODUCTS) ?<li className="nav-item"><Link to={'/admin/productcategories'}>Product Categories</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.PRODUCTS) ?<li className="nav-item"><Link to={'/admin/products'}>Products</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.EMAILTEMPLATE) ?<li className="nav-item"><Link to={'/admin/emailtemplate/home'}>Newsletters </Link></li>: ''}   
                                        {isPermitted(PERMISSIONS.BLOGS) ?<li className="nav-item"><Link to={'/admin/systems/home'}>Wiki</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.WEBTEMPLATE) ?<li className="nav-item"><Link to={'/admin/webtemplate/home'}>Blog Templates</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.USERS) ?<li className="nav-item"><Link to={'/admin/users'}>Users</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.ROLES) ?<li className="nav-item"><Link to={'/admin/roles'}>Roles</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.ORDERS) ?<li className="nav-item"><Link to={'/admin/orders'}>Orders</Link></li>: ''}
                                        {isPermitted(PERMISSIONS.PRODUCTS) ?<li className="nav-item"><Link to={'/admin/downloadrequests'}>Download Link Requests</Link></li>: ''}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </Affix>
    );
};

export default withRouter(AdminHeader);
