
import React, { useEffect, useState } from "react";
import './nav.less';
import { GetBlogs } from "./service";
import { Menu, Icon } from 'antd';
import { removeHTML } from "../../../../Helpers";
import { DownSquareOutlined, UpSquareOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;

const TopicsNavigation = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogsHidden, setBlogsHidden] = useState([]);
  const [collapsedSubNav, setCollapsedSubNav] = useState(true);
  useEffect(() => {
    async function loadData() {
      const { data } = await GetBlogs();
      const blogs = data.slice(0, 7);
      const blogsHidden = data.slice(7);
      setBlogs(data);
      setBlogsHidden(blogsHidden);
    }
    loadData();
  }, []);

  const handleClick = (e) => {
    console.log('click ', e);
    
  };

  return (
    <nav id="nav-topic" className="navbar navbar-expand-lg content-nav d-flex justify-content-center">
      <button class="navbar-toggler float-xs-right" type="button" 
      data-toggle="collapse" 
      data-target="#navbarSupportedContent" 
      aria-controls="navbarSupportedContent" 
      aria-expanded="false" 
      aria-label="Toggle navigation"
      style={{left:'calc(100% - 50px)', background: '#FFFFFF'}}
      onClick={() => {
        setCollapsedSubNav(!collapsedSubNav);
      }}>
        {collapsedSubNav ? <DownSquareOutlined />:<UpSquareOutlined />}
      </button>
      <div class={
                    (collapsedSubNav ? "collapse " : "") +
                    "navbar-collapse navbar-collapse-topic justify-content-center"
                  } id="navbarSupportedContent">
        <ul className="navbar-nav main-nav inner-nav align-items-md-center navbar-nav-topic" style={{overflow:'hidden'}}>
          {blogs ? blogs.map((element, index) => (
            <li key={index} className="nav-item">

              <a className="nav-link" href={`/systems/${element._id}`}>
                {removeHTML(element.title)}
              </a>
            </li>
          )) : ''}
          {/*blogsHidden.length? (
            <li key="combined_menu">
            <Menu onClick={(e) => handleClick(e)} mode="horizontal">
              <SubMenu
                title={
                  <span style={{fontSize: '20px'}} className="submenu-title-wrapper">
                    ...
                  </span>
                }
              >
                {blogsHidden.map((element, index) => (
                  <Menu.Item key={"sub_"+index}>
                    <a className="nav-link" href={`/systems/${element.title.trim().toLowerCase()}`}>
                      {element.title}
                    </a>
                  </Menu.Item>
                ))}
              </SubMenu>
            </Menu>
          </li>
                ):''*/}
        </ul>
      </div>
    </nav>
  );
}
export default TopicsNavigation;