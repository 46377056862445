import { getData, deleteData, postData, putData } from './../../apis/request';

export async function GetProducts() {
    const products = await getData('products');
    return products;
}


//User apis
export async function GetUsers() {
    const users = await getData('users/limtedinfo');
    return users;
}

export async function deleteUsers(productId) {
    const users = await deleteData(`users/${productId}`);
    return users;
}

export async function addUser(body) {
    const { data } = await postData('users', body);
    return data;
}

export async function updateUser(body) {
    const { data } = await putData(`users`, body);
    return data;
}

export async function GetUserRole() {
    const products = await getData(`users/getuserrole`);
    return products;
}

export async function GetUserById(productId) {
    const products = await getData(`users/limtedinfo/${productId}`);
    return products;
}

// export async function GetUserLimitedInfoById(userId) {
//     const products = await getData(`users/limtedinfo/${userId}`);
//     return products;
// }

export async function GetProductById(productId) {
    const products = await getData(`products/${productId}`);
    return products;
}

export async function deleteProduct(productId) {
    const products = await deleteData(`products/${productId}`);
    return products;
}

export async function addProduct(body) {
    const { data } = await postData('products', body);
    return data;
}

export async function UpdateProduct(body) {
    const products = await putData('products/update', body);
    return products;
}

export async function getProductsByCategory(body) {
    const products = await postData('products/bycategory', body);
    return products;
}

export async function UpdateOrder(body) {
    const blogs = await postData(`products/orderUpdate`, body);
    return blogs;
}

//product categories api
export async function GetProductCategories() {
    const products = await getData('productcategories');
    return products;
}

export async function GetProductCategoryById(categoryId) {
    const products = await getData(`productcategories/${categoryId}`);
    return products;
}

export async function deleteProductCategory(categoryId) {
    const products = await deleteData(`productcategories/${categoryId}`);
    return products;
}

export async function addProductCategory(body) {
    const { data } = await postData('productcategories', body);
    return data;
}

export async function UpdateProductCategory(body) {
    const products = await putData('productcategories/update', body);
    return products;
}

export async function UpdateOrderIndex(body) {
    const blogs = await postData(`productcategories/orderUpdate`, body);
    return blogs;
}

export async function updateCustom(id, body) {
    try {
        const data = await putData(`products/customUpdate/${id}`, body);
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function UpdateCart(body, productId){
    try {
        const data = await putData(`order/updateCart/${productId}`, body);
        return data;
    }
    catch (err) {
        return err;
    }
}