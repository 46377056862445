import React from "react";
import { useLocation } from 'react-router-dom';
import "./style.less";
import { Link } from "react-router-dom";
import { Form, Input, Button } from 'antd/lib/index';
import { Subscribe } from "./service";
import toastr from 'toastr';
import logoCircle from "../../../containers/Homepage/images/circle_logo.png";
const FormItem = Form.Item;

const Footer = props => {
  async function SubscribeUser(body) {
    try{
    const res = await Subscribe(body);
if(res.status==201)
{
  toastr.success("Subscribed successfully<br/>Please review your email for more details.");

  if(res.status==422)
  {
   toastr.error("User already subscribed");
  }}
    }
    catch(err)
    {
      console.log("Eerror",err);
if(err.status==422)
{
  toastr.error("User already subscribed");
}
    }

  }
  function handleSubmit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        try {
          SubscribeUser(values);
          toastr.options.closeButton = true;
          //toastr.options.timeOut = 30000000; // How long the toast will display without user interaction
//toastr.options.extendedTimeOut = 6000000;
          // toastr.info("Subscribed successfully<br/>Please review your email for more details.");
          // toastr.warning("Subscribed successfully");
          // toastr.error("Subscribed successfully");
        }
        catch (err) {

        }
      }
    });
  }
  const { getFieldDecorator } = props.form;
  const location = useLocation();
  if(location.pathname == '/'){
    return (
    <footer id="footer" className="section-background footer-landing">
      <div className="container">
        <div className="row">
          <Form onSubmit={(e) => handleSubmit(e)}
            className="form-inline footer-form"
          >
            <div class="form-group mx-sm-3 mb-2">
            <FormItem>
              {getFieldDecorator('email', {
                rules: [{ required: true, message: 'Email is required!' }],
              })(
                <Input className="form-control" placeholder="Enter in Email Address" style={{fontFamily:'nexa_regular', height:'40px'}}/>
              )}
            </FormItem>
            <Button className='btn btn-email-alert ml-sm-5' htmlType="submit">Sign up for Alerts</Button>
            </div>
          </Form>
        </div>
        <div className="row">
          <ul className="social-links d-flex justify-content-center align-items-center center-div">
            <li className="nav-item">

              <a 
              title="Mail"
              className="nav-link text-white"
                target="_blank"
                href="mailto:info@zo-organized.com"
              >
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
              </a>{" "}
            </li>
            <li className="nav-item">

              <a 
              title="Facebook"
              className="nav-link text-white"
                target="_blank"
                href="https://www.facebook.com/zoorganized/"
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>{" "}
            </li>
            <li className="nav-item">

              <a 
              title="Instagram"
              className="nav-link text-white"
                target="_blank"
                href="https://www.instagram.com/zoorganized/"
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>{" "}
            </li>
            <li className="nav-item">

              <a 
              title="Pinterest"
              className="nav-link text-white"
                target="_blank"
                href="https://www.pinterest.com/zoorganized/"
              >
                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
              </a>{" "}
            </li>
            <li className="nav-item">

              <a 
              title="Twitter"
              className="nav-link text-white"
                target="_blank"
                href="https://twitter.com/zo_organized">
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>{" "}
            </li>
          </ul>
        </div>
        <div className="row d-flex justify-content-center align-items-center pb-5">
          <div className='logoMin pull-left'>
            <figure>
              <img src={logoCircle} alt="logo" className="img-fluid"/>
            </figure>
            {/*<p className='label'>ZO</p>*/}
          </div>
        </div>
      </div>
      <div class="row" style={{background:'#e9e9e9', padding:"100px 0"}}>
          <div className="container">
            <div className="col-md-12">
              <div className='row'>
                <div className="col-md-3 col-sm-6">
                  <div class="list-group text-uppercase text-center text-md-left text-lg-left">
                    <a href="#" class="list-group-item list-group-item-action">FEATURES</a>
                    <a href="#" class="list-group-item list-group-item-action">Feature Overview</a>
                    <a href="#" class="list-group-item list-group-item-action">Lists</a>
                    <a href="#" class="list-group-item list-group-item-action">Tasks</a>
                    <a href="#" class="list-group-item list-group-item-action">Notes</a>
                    <a href="#" class="list-group-item list-group-item-action">Calendar</a>
                    <a href="#" class="list-group-item list-group-item-action">File Cabinet</a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 text-center text-md-left text-lg-left">
                  <div class="list-group text-uppercase mt-4 mt-md-0 mt-sm-0">
                    <a href="#" class="list-group-item list-group-item-action">SUPPORT & RESOURCES</a>
                    <a href="#" class="list-group-item list-group-item-action">Support</a>
                    <a href="#" class="list-group-item list-group-item-action">FAQ</a>
                    <a href="#" class="list-group-item list-group-item-action">How to Get Started</a>
                    <a href="#" class="list-group-item list-group-item-action">Contact Us</a>
                    <a href="#" class="list-group-item list-group-item-action">Integrating</a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mt-4 mt-md-0 text-center text-md-left text-lg-left footer-section-third">
                  <div class="list-group text-uppercase">
                    <a href="#" class="list-group-item list-group-item-action">NEWS</a>
                    <a href="#" class="list-group-item list-group-item-action">Newsletter</a>
                    <a href="#" class="list-group-item list-group-item-action">Blog</a>
                    <a href="#" class="list-group-item list-group-item-action">Why ZO?</a>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6 mt-4 mt-md-0 text-center text-md-left text-lg-left">
                  <div class="list-group text-uppercase">
                    <a href="#" class="list-group-item list-group-item-action">ABOUT US</a>
                    <a href="#" class="list-group-item list-group-item-action">Meet the Team</a>
                    <a href="#" class="list-group-item list-group-item-action">Story & Mission</a>
                    <a href="#" class="list-group-item list-group-item-action">Contact</a>
                    <a href="#" class="list-group-item list-group-item-action">Advertise with ZO</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="footer-copyright text-center py-3">
        <a href="#">Privacy Policy</a>
        <a href="#">Terms of Use</a>
        <a href="#">Access Your Account</a>
      </div>
    </footer>);
  }else{
  return (
    <footer id="footer">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-5">
            <h3>stay connected</h3>
            <div className="news-letter">
              <span className="title">Newsletter</span>
              <Form onSubmit={(e) => handleSubmit(e)}
                className="news-letter-form text-center justify-content-center"
              >
                <label className="label text-center">
                  Email address:
                </label>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{ required: true, message: 'Email is required!' }],
                  })(
                    <Input className="form-control" placeholder="Your email address" />
                  )}
                </FormItem>
                <Button className='text-center' htmlType="submit">Sign UP</Button>
              </Form>
            </div>
            <ul className="social-links d-flex justify-content-center align-items-center">
              <li className="nav-item">

                <a 
                title="Mail"
                className="nav-link"
                  target="_blank"
                  href="mailto:info@zo-organized.com"
                >
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                </a>{" "}
              </li>
              <li className="nav-item">

                <a 
                title="Facebook"
                className="nav-link"
                  target="_blank"
                  href="https://www.facebook.com/zoorganized/"
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>{" "}
              </li>
              <li className="nav-item">

                <a 
                title="Instagram"
                className="nav-link"
                  target="_blank"
                  href="https://www.instagram.com/zoorganized/"
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>{" "}
              </li>
              <li className="nav-item">

                <a 
                title="Pinterest"
                className="nav-link"
                  target="_blank"
                  href="https://www.pinterest.com/zoorganized/"
                >
                  <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                </a>{" "}
              </li>
              <li className="nav-item">

                <a 
                title="Twitter"
                className="nav-link"
                  target="_blank"
                  href="https://twitter.com/zo_organized">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>{" "}
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h3>zo topics</h3>
            <ul className="footer-nav list-style-none m-0 p-0">
              {/* <li>
                <Link className="nav-link" to={"/app"}>
                  ZO App
                </Link>
              </li> */}
              <li>
                <Link className="nav-link" to={"/about"}>
                  About ZO
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/systems/home"}>
                  zo systems
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/zoblog"}>
                  blog
                </Link>
              </li>
              {/* <li>
                <Link className="nav-link" to={"/products"}>
                  products
                </Link>
              </li> */}
              <li>
                <Link className="nav-link" to={"/"}>
                  zo home
                </Link>
              </li>
            </ul>
          </div>
          {/* <div className="col-md-4">
            <h3>search categories</h3>
            <select>
              <option >Select category</option>
              <option>Select category 1</option>
              <option>Select category 2</option>
              <option>Select category 3</option>
            </select>
          </div> */}
        </div>
      </div>
      {/*<Footer>© 2019 ZO Organized</Footer>*/}
    </footer>
  );
  }
};
const FooterForm = Form.create()(Footer);
export default FooterForm;
