import React, { useEffect, useState } from "react";
import { Redirect, withRouter, Link } from 'react-router-dom';
import { Button, Select, Input, Form, Switch, Breadcrumb, Table, Layout, Menu } from "antd";
import { getMenue } from '../auth/service';
import { PERMISSIONS } from "../../shared/modules/Enums/PermissionEnums";
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

function isPermitted(permission) {
    let permissions = localStorage.getItem("PERMISSIONS");
    if (permissions) {
        if (permissions.indexOf(permission) > -1) {
            return true;
        }
        return false;
    }
    return false;
}

function SideMenu(props) {
    // const [menue, setMenue] = useState([]);
    // useEffect(() => {
    //     async function fetchData() {
    //         const { data } = await getMenue(localStorage.getItem("ID"));
    //         setMenue(data);
    //     }
    //     fetchData();
    // }, []);

    return (
        <Sider width={200} className="site-layout-background d-none d-lg-block"
            style={{ background: "white" }}>
            <Menu
                mode="inline"
                defaultSelectedKeys={[props.selectedKey]}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%', width: '100%', borderRight: 0 }}
            >
            {isPermitted(PERMISSIONS.DASHBOARD) ? <Menu.Item key="9"><Link to={'/admin/Dashboard'}>Dashboard</Link></Menu.Item> : ''}
            {isPermitted(PERMISSIONS.PRODUCTS) ?<Menu.Item key="10"><Link to={'/admin/productcategories'}>Product Categories</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.PRODUCTS) ?<Menu.Item key="1"><Link to={'/admin/products'}>Products</Link></Menu.Item>: ''}
            {/*isPermitted(PERMISSIONS.PRODUCTS) ?<Menu.Item key="11"><Link to={'/admin/inventory'}>Inventory</Link></Menu.Item>: ''*/}
            {isPermitted(PERMISSIONS.EMAILTEMPLATE) ?<Menu.Item key="2" ><Link to={'/admin/emailtemplate/home'}>Newsletters </Link></Menu.Item>: ''}   
            {isPermitted(PERMISSIONS.BLOGS) ?<Menu.Item key="3"><Link to={'/admin/systems/home'}>Wiki</Link></Menu.Item>: ''}
            {/*isPermitted(PERMISSIONS.PAGE_PREFERENCES) ?<Menu.Item key="4"><Link to={'/admin/pagePrefrences'}>Page Preferences</Link></Menu.Item>: ''*/}
            {isPermitted(PERMISSIONS.WEBTEMPLATE) ?<Menu.Item key="5"><Link to={'/admin/webtemplate/home'}>Blog Templates</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.USERS) ?<Menu.Item key="6"><Link to={'/admin/users'}>Users</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.ROLES) ?<Menu.Item key="7"><Link to={'/admin/roles'}>Roles</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.ORDERS) ?<Menu.Item key="8"><Link to={'/admin/orders'}>Orders</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.PRODUCTS) ?<Menu.Item key="12"><Link to={'/admin/downloadrequests'}>Download Link Requests</Link></Menu.Item>: ''}
            {isPermitted(PERMISSIONS.PRODUCTS) ?<SubMenu key="sub4" title="Reports" style={{paddingLeft:'24px'}}>
                <Menu.Item key="13"><Link to={'/admin/salesreport'}>Sales Report</Link></Menu.Item>
                <Menu.Item key="14"><Link to={'/admin/inventorysummary'}>Inventory Summary</Link></Menu.Item>
                <Menu.Item key="15"><Link to={'/admin/ordercancelhistory'}>Order Cancel History</Link></Menu.Item>
            </SubMenu>: ''}
            </Menu>
        </Sider>
    );
}

export default withRouter(SideMenu) ;
