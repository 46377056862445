import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import styles from './unsubscripton-success.module.scss';
import { GetPrefrences, UpdatePrefrences } from '../../containers/prefrences/services';
const UnscubscriptionSuccess = (props) => {

    return (
        <>
            <Container fluid={true} className={styles['unsubscription']}>
                <Row>
                    <Col md='6' className='px-2 text-center'>
                        <Link to={"/"} className="logo-holder">
                            <img
                                src='/images/logo2.png'
                                alt="Zo"
                                className="img-fluid"
                                style={{ width: "50px" }}
                            />
                        </Link>
                        <h2>You have successfully Unsubscribed!</h2>
                        <p>Now, you won't receive any email for the categories that you have unsubscribed.</p>
                        <div className={styles['social-links']}>
                            <a
                                target="_blank"
                                href="https://www.facebook.com/zoorganized/"
                            >
                                <i className="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a
                                target="_blank"
                                href="https://www.instagram.com/zoorganized/3"
                            >
                                <i className="fa fa-instagram" aria-hidden="true"></i>
                            </a>
                            <a
                                target="_blank"
                                href="https://www.pinterest.com/zoorganized/"
                            >
                                <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                            <a target="_blank" href="https://twitter.com/zo_organized">
                                <i className="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                        </div>
                    </Col>
                    <Col md='6' style={{ backgroundColor: 'rgb(255,243,204)' }} className='vh-100'>
                        <img src='/images/postman.png' width='300px' style={{ display: 'block', margin: 'auto' }} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default withRouter(UnscubscriptionSuccess);