import { getData, postData } from '../../../apis/request';


export async function getOrdersAmountByDate() {
    try {
        const data = await getData(`Order/listByDate`);
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function getOrderList() {
    try {
        const data = await getData(`Order/listAll`);
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function getOrderCountByStatus(date = new Date()) {
    try {
        const data = await postData(`order/countByStatus`, {date});
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function getRecentDepositsAmount() {
    try {
        const data = await getData(`order/RecentDepositsAmount`);
        return data;
    }
    catch (err) {
        return err;
    }
}
