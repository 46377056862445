import { getData, deleteData, postData, putData } from '../../../apis/request';

export async function getMenu() {
    try {
        const data = await getData("organizationalprefrences");
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function saveMenu(body) {
    try {
        const data = await postData("organizationalprefrences/save", body);
        return data;
    }
    catch (err) {
        return err;
    }
}

// export async function GetTemplateById(productId) {
//     try {
//         const data = await getData(`emailtemplate/${productId}`);
//         return data;
//     }
//     catch (err) {
//     }
// }

// export async function deleteTemplate(productId) {
//     const data = await deleteData(`emailtemplate/${productId}`);
//     return data;
// }

// export async function addTemplate(body) {
//     const { data } = await postData('emailtemplate', body);
//     return data;
// }
// export async function updateTemplate(body) {
//     const { data } = await putData('emailtemplate', body);
//     return data;
//}
