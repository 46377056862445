import React, { useState, useReducer, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthContextContainer from './shared/components/Context/AuthContext/AuthContextContainer';
import ErrorBoundary from './shared/modules/ErrorBoundary';
import './App.less';
import './common.less';
import { Routes } from './routes/Routes/routes';
import Context from "./Context";
import { CART } from './shared/modules/Enums/cartEnums';
import { GetProducts } from "./containers/Products/service";
import ScrolltoTop from './ScrolToTop';
import 'grapesjs/dist/css/grapes.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "bootstrap/dist/css/bootstrap.css";
const CART_STATE = 'ZOREACT_CART';
const cartReducer = (state, action) => {
    
    switch (action.type) {
        case CART.ADD_TO_CART:        
   return addToCart(state, action);
        case CART.REMOVE_FROM_CART:
            return removeFromCart(state, action);
        case CART.ADD_QUANTITY:
            return addQuantity(state, action);
        case CART.SUBTRACT_QUANTITY:
            return subQuantity(state, action);
        case CART.ADD_PRODUCTS:
            return addProducts(state, action);
        default:
            return state;
            
    }
};

export const loadState = () => {
    try {
      const serializedState = localStorage.getItem(CART_STATE);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return undefined;
    }
  }; 
  
export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(CART_STATE, serializedState);
    } catch {
      // ignore write errors
    }
  };

function addToCart(state, action) {
    let addedItem = state.items.find(item => item._id === action._id)
    //check if the action id exists in the addedItems
    let existed_item = state.addedItems.find(item => action._id === item._id)
    if (existed_item) {
        if(action.downloadable == true){
            return {
                ...state,
                total: state.total
            }
        }
        existed_item.quantity += action.quantity
        saveState({
            ...state,
            total: state.total + (existed_item.price * action.quantity)
        });
        return {
            ...state,
            total: state.total + (existed_item.price * action.quantity)
        }
    } else {
        addedItem.quantity = action.quantity;
        //addedItem.downloadable = action.downloadable;
        //calculating the total
        let newTotal = state.total + (addedItem.price * action.quantity)
        saveState({
            ...state,
            addedItems: [...state.addedItems, addedItem],
            total: newTotal
        });
        return {
            ...state,
            addedItems: [...state.addedItems, addedItem],
            total: newTotal
        }

    }
}

function removeFromCart(state, action) {
    let itemToRemove = state.addedItems.find(item => action._id === item._id)
    let new_items = state.addedItems.filter(item => action._id !== item._id)

    //calculating the total
    let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity)
    saveState({...state,
        addedItems: new_items,
        total: newTotal})
    return {
        ...state,
        addedItems: new_items,
        total: newTotal
    }
}

function addQuantity(state, action) {
    let addedItem = state.addedItems.find(item => item._id === action._id);
    addedItem.quantity += 1;
    let newTotal = state.total + addedItem.price
    saveState({...state,
        total: newTotal})
    return {
        ...state,
        total: newTotal
    }
}

function subQuantity(state, action) {
    let addedItem = state.addedItems.find(item => item._id === action._id)
    //if the qt == 0 then it should be removed
    if (addedItem.quantity === 1) {
        let new_items = state.addedItems.filter(item => item._id !== action._id)
        let newTotal = state.total - (addedItem.price * addedItem.quantity)
        saveState({...state,
            addedItems: new_items,
            total: newTotal})
        return {
            ...state,
            addedItems: new_items,
            total: newTotal
        }
        /*let newTotal = state.total - addedItem.price
        saveState({...state,
            addedItems: new_items, 
            total: newTotal})
        return {
            ...state,
            addedItems: new_items,
            total: newTotal
        }*/
    } else {
        addedItem.quantity -= 1 
        let newTotal = state.total - addedItem.price
        saveState({...state,
            total: newTotal})
        return {
            ...state,
            total: newTotal
        }
    }
}

function addProducts(state, action) {
   saveState({...state,
    items: action.payload})
    return {
        ...state,
        items: action.payload
    }
}
const App = () => {
    const initialState = {
        items: [],
        addedItems: [],
        total: 0    
    };
    
     const CartValue = loadState();
     console.log(CartValue);
    const [state, dispatch] = useReducer(cartReducer, CartValue ? CartValue : initialState) 
    useEffect(() => {
        async function fetchData() {
            const { data } = await GetProducts();
            dispatch({ type: CART.ADD_PRODUCTS, payload: data });
        }
        fetchData();
    }, [])
    return (
        <Context.Provider
            value={{
                state,
                dispatch,
            }}>
                
            <BrowserRouter>
                <ErrorBoundary>
                    <AuthContextContainer>
                        <ScrolltoTop>
                            <Routes />
                        </ScrolltoTop>
                    </AuthContextContainer>
                </ErrorBoundary>
            </BrowserRouter>
        </Context.Provider>
    );
}

export default App;

