import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/fonts/SilverSouthScript.ttf';
import './assets/fonts/IMFellRegular.ttf';
import './assets/fonts/Aileron/Aileron-Thin.ttf';

const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

ReactDOM.render(<App />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
