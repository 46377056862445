import React, { Suspense, useContext, useState } from "react";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import Navigation from "../../shared/components/Navigation";
import Footer from "../../shared/components/Footer";
import { Loading } from "../../shared/modules/Loading";
import Unsubscribe from "../../containers/Unsubscribe";
import UnsubscriptionSuccess from '../../containers/UnsubscriptionSuccess';

import { AuthContext } from "../../shared/components/Context/AuthContext/AuthContext";
import AdminLayout from '../../shared/layouts/admin';
import AdminDashboard from "../../containers/Admin/dashboard/index";
import { PERMISSIONS } from "../../shared/modules/Enums/PermissionEnums";
import { verifyEmail } from "../../containers/auth/service";
const userMenu = React.lazy(() => import("../../containers/userMenue/pages"));
const Prefrences = React.lazy(() => import("../../containers/prefrences/index"));
const PagePrefrences = React.lazy(() => import("../../containers/Admin/prefrences/index"));
const BlogContainer = React.lazy(() => import("../../containers/BlogPost"));
const AboutContainer = React.lazy(() => import("../../containers/About"));
const MeetTeam = React.lazy(() => import("../../containers/MeetTeam"));
//const Checkout = React.lazy(() => import("../../containers/Cart/checkout"));
const Checkout = React.lazy(() => import("../../containers/PymentProcess/checkOut"));

const Shipping = React.lazy(() => import("../../containers/PymentProcess/shipping"));
const Payment = React.lazy(() => import("../../containers/PymentProcess/payment"));
const BillingAddress = React.lazy(() => import("../../containers/PymentProcess/billingAddress"));
const ViewCart = React.lazy(() => import("../../containers/Cart/index"));
const TreeContainer = React.lazy(() => import("../../containers/Admin/blogTree/container"));
const BlogInput = React.lazy(() => import("../../containers/Admin/blogTree/inputFormBlog"));
const Blogs = React.lazy(() => import("../../containers/Admin/blogTree/index"));
const AdminProductsContainer = React.lazy(() => import("../../containers/Admin/Products"));
const AdminInventoryContainer = React.lazy(() => import("../../containers/Admin/Inventory"));
const AdminProductCategoriesContainer = React.lazy(() => import("../../containers/Admin/ProductCategories"));
const Orders = React.lazy(() => import("../../containers/Admin/Orders/container"));

const OrderDetail = React.lazy(() => import("../../containers/Admin/OrderDetail/container"));
const AdminUsersContainer = React.lazy(() => import("../../containers/Admin/User"));
const AdminRolesContainer = React.lazy(() => import("../../containers/Admin/Roles"));
const BlogsContainer = React.lazy(() => import("../../containers/Blogs"));
const ProductsContainer = React.lazy(() => import("../../containers/Products"));
const EmailTemplates = React.lazy(() => import("../../containers/Admin/emailTemplates/container"));
const WebTemplates = React.lazy(() => import("../../containers/Admin/webTemplates/container"));

//const Test = React.lazy(() => import("../../containers/Admin/emailTemplates/test2"));
//const TestWebTemplate = React.lazy(() => import("../../containers/Admin/webTemplates/test2"));

const InputFormEmailTemplate = React.lazy(() => import("../../containers/Admin/emailTemplates/inputForm"));
const InputFormWebTemplate = React.lazy(() => import("../../containers/Admin/webTemplates/inputForm"));

const DefaultComponent = React.lazy(() => import("../../containers/Homepage/index"));
const LoginComponent = React.lazy(() => import("../../containers/auth/Login"));
const SignUpComponent = React.lazy(() =>
  import("../../containers/auth/Register")
);

const ForgetPassword = React.lazy(() => import("../../containers/auth/forgetPassword"));
const ResetPassword = React.lazy(() => import("../../containers/auth/resetPassword"));
const VerifyEmail = React.lazy(() => import("../../containers/auth/verifyEmail"));
const Main = React.lazy(() => import("../../containers/Main"));
const Wiki = React.lazy(() => import("../../containers/Wiki/container"));
const Profile = React.lazy(() => import("../../containers/Admin/Profile/container"));
const Wiki2 = React.lazy(() => import("../../containers/Wiki/types"));
const WikiHome = React.lazy(() =>
  import("../../containers/Wiki/WikiHome")
);
const zoBlog = React.lazy(() => import("../../containers/ZoBlog"));
const ProductDescription = React.lazy(() =>
  import("../../containers/Products/description")
);
const AdminAddEditProductsContainer = React.lazy(() =>
  import("../../containers/Admin/Products/inputForm")
);
const AdminAddEditProductCategoriesContainer = React.lazy(() =>
  import("../../containers/Admin/ProductCategories/inputForm")
);
const AdminAddEditRolesContainer = React.lazy(() =>
  import("../../containers/Admin/Roles/addEdit")
);
const AdminAddEditUsersContainer = React.lazy(() =>
  import("../../containers/Admin/User/addEdit")
);
const Searchpage = React.lazy(() => import("../../containers/Searchpage"));
const OrderStatus = React.lazy(() => import("../../containers/OrderStatus"));
const AdminDownloadRequestContainer = React.lazy(() => import("../../containers/Admin/downloadRequests"));
const AdminSalesReport = React.lazy(() => import("../../containers/Admin/Reports/SalesReport"));
const AdminInventorySummary = React.lazy(() => import("../../containers/Admin/Reports/inventorySummary"));
const AdminCancelOrderHistory = React.lazy(() => import("../../containers/Admin/Reports/orderCancelHistory"));
const PrivacyPolicyPage = React.lazy(() => import("../../containers/PrivacyPolicy"));
// const AdminAddEditBlogsContainer = React.lazy(() =>
//   import("../../containers/Admin/Blogs/addEdit")
// );

export const Routes = () => {

  const context = useContext(AuthContext);
  // const ProtectedRoute = ({ component: Component, ...rest }) => (
  //   <Route
  //     {...rest}
  //     render={(props) =>
  //       context.authenticateRoute(rest.permission) ? (
  //         <>
  //           <Navigation />
  //           <Component {...props} />
  //           <Footer />
  //         </>
  //       ) : (
  //           <Redirect
  //             to={{
  //               pathname: "/",
  //               state: { from: props.location },
  //             }}
  //           />
  //         )
  //     }
  //   />
  // );
  const { checkOut, setCheckOut } = useState(null);
  const UserRoute = ({ component: Component, ...rest }) => {
    var value = { ...rest };
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <>
            <Component
              checkOut={checkOut}
              setCheckOut={setCheckOut}
              {...matchProps} />
          </>
        )}
      />
    );
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(matchProps) =>
          context.authenticateRoute(rest.permission) ? (
            <>
              <Component {...matchProps} />
            </>
          ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: matchProps.location },
                }} />
            )} />
    )
  };
  return (

    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          key={"/"}
          path={"/"}
          exact={true}
          component={DefaultComponent}
        />
        <Route
          key={"/login"}
          path={"/login"}
          exact={true}
          component={LoginComponent}
        />
        <Route
          key={"/register"}
          path={"/register"}
          exact={true}
          component={SignUpComponent}
        />
        <Route
          key={"/forgetPassword"}
          path={"/forgetPassword"}
          exact={true}
          component={ForgetPassword}
        />
        <Route
          key={"/resetPassword"}
          path={"/resetPassword"}
          //exact={true}
          component={ResetPassword}
        />
        <Route
          key={"/verifyEmail"}
          path={"/verifyEmail"}
          //exact={true}
          component={VerifyEmail}
        />

        <Route
          key={"/unsubscribe"}
          path={"/unsubscribe"}
          //exact={true}
          component={Unsubscribe}
        />

        <Route
          key={"/unsubscription-success"}
          path={"/unsubscription-success"}
          //exact={true}
          component={UnsubscriptionSuccess}
        />

        {/* <Route key={'/error'} path={'/error'} exact={true} component={DefaultComponent} /> */}
        <Route
          key={"/blog"}
          path={"/blog"}
          exact={true}
          component={Main}
        />
        <Route
          key={"/about"}
          path={"/about"}
          exact={true}
          component={AboutContainer}
        />
        <Route
          key={"/blogpost"}
          path={"/blogpost"}
          exact={true}
          component={BlogContainer}
        />
        <Route
          key={"/blog/financial"}
          path={"/blog/financial"}
          exact={true}
          component={Main}
        />
        <Route
          key={"/systems/home"}
          path={"/systems/home"}
          exact={true}
          component={WikiHome}
        />
        <Route key={"/systems"} path={"/systems/"} component={Wiki} />

        <Route key={"/blogtype"} path={"/blogtype"} component={Wiki2} />
        <Route
          key={"/meet-team"}
          path={"/meet-team/"}
          component={MeetTeam}
        />

        <Route key={"/zoblog"} path={"/zoblog"} component={zoBlog} />
        
        <Route
          key={"/products"}
          path={"/products"}
          component={ProductsContainer}
        />

        <Route
          key={"/product/:id"}
          path={"/product/:id"}
          exact={true}
          component={ProductDescription}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/products"}
          path={"/admin/products"}
          exact
          component={AdminProductsContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/inventory"}
          path={"/admin/inventory"}
          exact
          component={AdminInventoryContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/productcategories"}
          path={"/admin/productcategories"}
          exact
          component={AdminProductCategoriesContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/orders"}
          path={"/admin/orders"}
          exact
          component={Orders}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/ordersdetail/:id"}
          path={"/admin/ordersdetail/:id"}
          exact
          component={OrderDetail}
        />
        <ProtectedRoute
          permission={PERMISSIONS.USERS}
          key={"/admin/users"}
          path={"/admin/users"}
          exact
          component={AdminUsersContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/roles"}
          path={"/admin/roles"}
          exact
          component={AdminRolesContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PROFILE}
          key={"/profile"}
          path={"/profile"}
          exact
          component={Profile}
        />

        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/products/add/:id?"}
          path={"/admin/products/add/:id?"}
          exact
          component={AdminAddEditProductsContainer}
        />

        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/productcategories/add/:id?"}
          path={"/admin/productcategories/add/:id?"}
          exact
          component={AdminAddEditProductCategoriesContainer}
        />
        
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/Users/add/:id?"}
          path={"/admin/Users/add/:id?"}
          exact
          component={AdminAddEditUsersContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/Roles/add/:id?"}
          path={"/admin/Roles/add/:id?"}
          exact
          component={AdminAddEditRolesContainer}
        />
        <Route
          key={"/view-cart"}
          path={"/view-cart"}
          exact={true}
          component={ViewCart}
        />
        <Route
          key={"/checkout"}
          path={"/checkout"}
          exact={true}
          component={Checkout}
        />


        <Route
          key={"/shipping"}
          path={"/shipping"}
          exact={true}
          component={Shipping}
        />

        <Route
          key={"/payment"}
          path={"/payment"}
          exact={true}
          component={Payment}
        />

        <Route
          key={"/billingaddress"}
          path={"/billingaddress"}
          exact={true}
          component={BillingAddress}
        />
        <ProtectedRoute
          permission={PERMISSIONS.BLOGS}
          key={"/admin/systems/tree/:id?"}
          path={"/admin/systems/tree/:id?"}
          exact={true}
          component={TreeContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.BLOGS}
          key={"/admin/systems/blog/add/:id?"}
          path={"/admin/systems/blog/add/:id?"}
          exact={true}
          component={BlogInput}
        />

        <ProtectedRoute
          permission={PERMISSIONS.BLOGS}
          key={"/admin/systems/home"}
          path={"/admin/systems/home"}
          exact={true}
          component={Blogs}
        />

        <ProtectedRoute
          permission={PERMISSIONS.EMAILTEMPLATE}
          key={"/admin/emailtemplate/add/:id?"}
          path={"/admin/emailtemplate/add"}
          //exact={true}
          component={InputFormEmailTemplate}
        />
        {/* <ProtectedRoute
                        permission={PERMISSIONS.EMAILTEMPLATE}
                        key={"/admin/emailtemplate/test/:id?"}
                        path={"/admin/emailtemplate/test"}
                        exact={true}
                        component={Test}
                        /> */}

        <ProtectedRoute
          permission={PERMISSIONS.WEBTEMPLATE}
          key={"/admin/webtemplate/add/:id?"}
          path={"/admin/webtemplate/add"}
          //exact={true}
          component={InputFormWebTemplate}
        />
        {/* <ProtectedRoute
                        permission={PERMISSIONS.WEBTEMPLATE}
                        key={"/admin/webtemplate/test/:id?"}
                        path={"/admin/webtemplate/test"}
                        exact={true}
                        component={TestWebTemplate}
                        /> */}

        <ProtectedRoute
          permission={PERMISSIONS.EMAILTEMPLATE}
          key={"/admin/prefrences"}
          path={"/admin/prefrences"}
          exact={true}
          component={Prefrences}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PAGE_PREFERENCES}
          key={"/admin/pagePrefrences"}
          path={"/admin/pagePrefrences"}
          exact={true}
          component={PagePrefrences}
        />
        <ProtectedRoute
          permission={PERMISSIONS.EMAILTEMPLATE}
          key={"/admin/emailtemplate/home"}
          path={"/admin/emailtemplate/home"}
          // exact={true}
          component={EmailTemplates}
        />
        <ProtectedRoute
          permission={PERMISSIONS.WEBTEMPLATE}
          key={"/admin/webtemplate/home"}
          path={"/admin/webtemplate/home"}
          exact={true}
          component={WebTemplates}
        />

        {/* <Route
                          path={"/userPage/:url"}
                          component={}
                        /> */}
        <Route
          //key={"/userMenu"}
          path={"/userPage/:url"}
          // exact={true}
          component={userMenu}
        />

        {/* Blogs */}
        <Route
          key={"/blogs"}
          path={"/blogs"}
          component={BlogsContainer}
        />
        <ProtectedRoute
            permission={PERMISSIONS.PRODUCTS}
          key={"/admin/dashboard"}
          path={"/admin/dashboard"}
          component={AdminDashboard}
        />
        {/* 
                          <ProtectedRoute
                          permission={PERMISSIONS.BLOGS}
                          key={"/admin/blogs"}
                          path={"/admin/blogs"}
                          exact
                          component={AdminBlogsContainer}
                        /> */}
        {/* <ProtectedRoute
                          permission={PERMISSIONS.BLOGS}
                          key={"/admin/blogs/add/:id?"}
                          path={"/admin/blogs/add/:id?"}
                          exact
                          component={AdminAddEditBlogsContainer}
                        /> */}
        {/* End Blogs */}
        <Route
          key={"/search/:find?"}
          path={"/search/:find?"}
          component={Searchpage}
        />
        {/* route for order status page displaying after rder creation */}
        <Route
          key={"/orderstatus/:id?"}
          path={"/orderstatus/:id?"}
          component={OrderStatus}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/downloadrequests"}
          path={"/admin/downloadrequests"}
          exact
          component={AdminDownloadRequestContainer}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/salesreport"}
          path={"/admin/salesreport"}
          exact
          component={AdminSalesReport}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/inventorysummary"}
          path={"/admin/inventorysummary"}
          exact
          component={AdminInventorySummary}
        />
        <ProtectedRoute
          permission={PERMISSIONS.PRODUCTS}
          key={"/admin/ordercancelhistory"}
          path={"/admin/ordercancelhistory"}
          exact
          component={AdminCancelOrderHistory}
        />
        <Route
          key={"/privacypolicy"}
          path={"/privacypolicy"}
          component={PrivacyPolicyPage}
        />
      </Switch>
    </Suspense >
  );
};
