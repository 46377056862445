import React, { useState, useContext,useRef, useEffect } from "react";
import "./header.less";
import TopicsNavigation from "../SubNavigations/TopicsNavigation";
import { Affix, Badge, message,Form } from "antd";
import { Input, Button } from 'antd/lib/index';
import Context from "../../../Context";
//import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';
import { Route, Redirect, Link } from "react-router-dom";
import { CART } from "../../modules/Enums/cartEnums";
import { AuthContext } from "../Context/AuthContext/AuthContext";
import { withRouter } from 'react-router-dom';
import { getMenue } from '../../../containers/auth/service';
import { getMenu } from '../../../containers/Admin/prefrences/services';
import { BlackList, getNotifications, ReadAll } from './service';
import { fetchSingleFileSrc } from "../../../containers/Products/product.helper";
import socketIOClient from "socket.io-client";
import Notifications from "./notifications";
import toastr from "toastr";
import { UpdateCart } from "../../../containers/Products/service";
import "./cart_styling.css";

import { BASE_URL } from '../../../apis/request';
import logoCircle from "../../../containers/Homepage/images/circle_logo.png";

const FormItem = Form.Item;
// import {ShoppingCartOutlined} from '@ant-design/icons';
const Navigation = props => {
    
  const myRef = useRef(null);
    const handleClickOutside = e => {
      var className=typeof e.target.className;
      if(className=='string')
      {
        var id=(e.target.className.split('Hamburger'));
        if(id.length==1)
        {
          setCollapsed(true);
        }
      }
      
      //
    };
  
  
  const authContext = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(true);

  const { state, dispatch } = useContext(Context);
  const [notifications, SetNotifications] = useState([]);
  const [notificationsState, SetNotificationsState] = useState({
    items: 10,
    hasMoreItems: true
  });
  const [menue, setMenue] = useState([]);
  const [totalNotificatrions, setTotal] = useState(0);

  useEffect(() => {
    

    async function fetchData() {
      console.log("Page Reload");
      if (localStorage.getItem('TOKEN') != undefined) {
        GetNotifications(notificationsState.items)
        //const { data } = await getMenue(localStorage.getItem("ID"));

        const socket = socketIOClient(BASE_URL);
        socket.on("FromAPI", (message) => {
          GetNotifications(notificationsState.items);
        });
      }
    }
    fetchData();
    
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  async function GetNotifications(l) {
    const { data } = await getNotifications(l);
    SetNotifications(data.data);
    setTotal(data.total)
    console.log("Notification data", data);
  }
  function calculatTime(element) {
    const Time = new Date(element.createdAt);
    const currentTime = new Date();
    const diffrence = currentTime - Time;
    const seconds = diffrence / 1000;
    var minuts = 0;
    var hours = 0;
    var Days = 0;
    var weeks = 0;
    var returnTime = seconds.toFixed(0) + " seconds";
    if (seconds < 20) {
      returnTime = " just Now";
    }
    if (seconds > 60) {
      minuts = (seconds / 60);
      returnTime = minuts.toFixed(0) + " minutes";
    }
    if (minuts > 60) {
      hours = (minuts / 60);
      returnTime = hours.toFixed(0) + " hours";
    }
    if (hours > 24) {
      Days = (hours / 24);
      returnTime = Days.toFixed(0) + " days";
    }
    if (Days > 7) {
      weeks = (Days / 7);
      returnTime = weeks.toFixed(0) + " weeks";
    }
    return returnTime;
  }

  function loadMore() {
    if (notificationsState.items === 40) {
      SetNotificationsState({ ...notificationsState, hasMoreItems: false });
    }
    else {
      setTimeout(async () => {
        await GetNotifications(notificationsState.items + 10);
        SetNotificationsState({ ...notificationsState, items: notificationsState.items + 10 });
      }, 3000);
    }
  }

  async function onHover() {
    console.log("Hover");
    if (localStorage.getItem('TOKEN') != undefined) {
      const resp = await ReadAll(notificationsState.items);
      SetNotifications(resp.data.data);
      console.log("Notification data", resp.data);
      setTotal(resp.data.total)
    }
  }
  async function addQuantity(_id) {
    const {data} = await UpdateCart({type: 'add', quantity: 1}, _id);
    if(data != null && data.status == 'success'){
        toastr.success(data.message, "");
        dispatch({ type: CART.ADD_QUANTITY, _id })
    }else if(data != null && data.status == 'error'){
        toastr.error(data.message, '');
    }
  }
  async function subtractQuantity(_id) {
    const {data} = await UpdateCart({type: 'sub', quantity: 1}, _id);
    if(data != null && data.status == 'success'){
        toastr.success(data.message, "");
        dispatch({ type: CART.SUBTRACT_QUANTITY, _id });
    }else if(data != null && data.status == 'error'){
        toastr.error(data.message, '');
    }
  }
  function NavigateToAdminPanel() {
    let permissions = JSON.parse(localStorage.getItem("PERMISSIONS"));
    let permission = permissions[0];
    
    if(permissions.indexOf('dashboard')){
      props.history.push(`/admin/dashboard`);
    }else{
      props.history.push(`/admin/${permission}`);
    }
  }

  async function handleSearchSubmit(e) {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        try {
          console.log(props);
          props.history.push('/search/'+values.find);
          if(props.location.pathname.indexOf('search') > -1){
            window.location.reload(false);
          }
        }
        catch (err) {

        }
      }
    });
  };

  async function removeProduct(_id) {
    let itemToRemove = state.addedItems.find(item => _id === item._id)
    if(itemToRemove){
        const {data} = await UpdateCart({type: 'sub', quantity: itemToRemove.quantity}, _id);
        if(data != null && data.status == 'success'){
            toastr.success(data.message, "");
            dispatch({ type: CART.REMOVE_FROM_CART, _id });
        }else if(data != null && data.status == 'error'){
            toastr.error(data.message, '');
        }
    }
    //dispatch({ type: CART.REMOVE_FROM_CART, _id })
  }

  function logout() {
    state.addedItems.forEach(element => {
      removeProduct(element._id)
    });
    BlackList();
    authContext.logoutUser();
    props.history.push('/');
  }
  const { getFieldDecorator } = props.form;
  return (
    // style={{ position: 'fixed', marginBottom: '104.2pxpx', width: '100%', height: '104.2px', zIndex: '10' }}
    <Affix >
      <header id="header" className="text-uppercase">
        <div className="header-container">
          <div className="position-relative">
            {/* <div className="flex-shrink-0">
              <a href="/" className="logo-holder">
                <img src={logo} alt="Zo" className="img-fluid" />
              </a>
            </div> */}
            <div className="col">
              <nav id="nav" className="navbar navbar-expand-lg   text-md-right">
                <div className="d-none d-lg-block">
                  <Link to={"/"} className="logo-holder">
                    {/*<img
                      src='/images/logo2.png'
                      alt="Zo"
                      className="img-fluid"
                      style={{ width: "50px" }}
                    />*/}
                    <div className='logoMin pull-left'>
                      <figure>
                        <img src={logoCircle} alt="logo" className="img-fluid" style={{width:'60px'}}/>
                      </figure>
                      {/*<p className='label' style={{fontSize:'22px', top: '14.5px', marginLeft: '1px !important'}}>ZO</p>*/}
                    </div>
                  </Link>
                </div>
                <button
                  className={
                    "navbar-toggler ml-auto first-button outline-none shadow-none Hamburger"
                  }
                  type="button"
                  data-toggle="collapse"
                  data-target="#mainnavbarDropdown"
                  aria-controls="mainnavbarDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => {
                    setCollapsed(!collapsed);
                  }}
                >
                  <div  className="animated-icon1 Hamburger">
                    <span className="Hamburger"></span>
                    <span className="Hamburger"></span>
                    <span className="Hamburger"></span>
                  </div>
                </button>
            
                <div
                  className={
                    (collapsed ? "collapse p-3 p-md-0 " : "") +
                    "navbar-collapse justify-content-end p-3 p-md-0 Hamburger"
                  }
                  id="mainnavbarDropdown"
                >
                  <div className="d-inline-block d-lg-none">
                    <Link to={"/"} className="logo-holder">
                      {/*<img
                        src='/images/logo2.png'
                        alt="Zo"
                        className="img-fluid"
                        style={{ width: "50px" }}
                      />*/}
                      <div className='logoMin pull-left mobile-logo'>
                        <figure>
                          <img src={logoCircle} alt="logo" className="img-fluid" style={{width:"60px !important"}}/>
                        </figure>
                        {/*<p className='label' style={{fontSize:"22px !important", top:"14.5px !important"}}>ZO</p>*/}
                      </div>
                    </Link>
                  </div>

                  <ul className="navbar-nav main-nav align-items-lg-center align-items-center mx-md-auto">
                    {/*<li className="nav-item">*/}
                    {/*  <Link className="nav-link" to={"/app"}>*/}
                    {/*    ZO App*/}
                    {/*  </Link>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                      <Link className="nav-link" to={"/about"}>
                        about zo test
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/systems/home"}>
                        zo systems
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <div className="nav-link user-link">
                        <li className="nav-item">
                          <Link className="nav-link" to={"/zoblog"}>
                            blog
                      </Link>
                        </li>
                        {!authContext.checkAuthentication() ? '' : menue.length > 0 ?
                          <div className="user-popup dropdown-menu-left">
                            <div className="user-holder">
                              <ul className="" >
                                {menue ? menue.map((element, index) => (

                                  <li>
                                    <Link className="nav-link" to={`/userPage${element.url}`}>
                                      {element.name}
                                    </Link>
                                  </li>

                                )) : ''}
                              </ul>
                            </div>
                          </div>
                          : ''}
                      </div>
                    </li> */}
                    <li className="nav-item">
                      <div className="nav-link">
                        <li className="nav-item">
                          <Link className="nav-link" to={"/zoblog"}>
                            blog
                      </Link>
                        </li>
                        <div className="user-popup" style={{ right: '-150px' }}>
                          <div className="user-holder">
                            <ul className="" >
                              {menue ? menue.map((element, index) => (

                                <li className="nav-item">
                                  <Link className="nav-link" to={`/userPage${element.url}`}>
                                    {element.name}
                                  </Link>
                                </li>

                              )) : ''}
                            </ul>
                          </div>
                        </div>

                      </div>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to={"/products"}>
                        products
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to={"/"}>
                        zo home
                      </Link> 
                    </li>

                    {/* <li className="nav-item dropdown">User Menue
                    <ul class="dropdown-menu">
                        {menue ? menue.map((element, index) => (

                          <li><Link className="nav-link" to={`/userPage${element.url}`}>
                            {element.name}
                          </Link>
                          </li>

                        )) : ''}
                      </ul>
                    </li> */}
                  </ul>
                  <div className="d-none">
                    <TopicsNavigation />
                  </div>
                  <ul className="social-links navbar-nav align-items-md-right d-flex">
                    <li className="nav-item">
                      <a
                        title="Mail"
                        className="nav-link"
                        target="_blank"
                        href="mailto:info@zo-organized.com"
                      >
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        title="Facebook"
                        className="nav-link"
                        target="_blank"
                        href="https://www.facebook.com/zoorganized/"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        title="Instagram"
                        className="nav-link"
                        target="_blank"
                        href="https://www.instagram.com/zoorganized/"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        title="Pinterest"
                        className="nav-link"
                        target="_blank"
                        href="https://www.pinterest.com/zoorganized/"
                      >
                        <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        title="Twitter"
                        className="nav-link" target="_blank" href="https://twitter.com/zo_organized">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        title="Youtube"
                        className="nav-link" target="_blank" href="https://www.youtube.com/">
                        <i className="fa fa-youtube" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>

                </div>

                <form className="search-form d-md-flex d-none" action="/search" onSubmit={(e) => handleSearchSubmit(e)}>
                  <FormItem className="navigation-search">
                    {getFieldDecorator('find', {
                        rules: [{ required: true, message:''}],
                    })(
                        <Input type="search" name="find" className="form-control border-0" placeholder="SEARCH..."  style={{width:'100%'}}/>
                    )}
                  </FormItem>
                  <button type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>

                {/* Notifications */}
                {authContext.checkAuthentication() ?
                  <>
                    <div className="nav-link cart-link notify-link d-md-none d-sm-none d-none" style={{ marginRight: '-32px' }} onMouseEnter={onHover}>
                      <Badge count={notifications && totalNotificatrions} showZero={false} >
                        <i className="fa fa-bell text-light text-light1"></i>
                      </Badge>
                      <div className="cart-popup notify-popup" style={{ minWidth: '1100px' }}>
                        <div className="Notification-holder" >
                          <InfiniteScroll
                            loadMore={() => loadMore()}
                            hasMore={notificationsState.hasMoreItems}
                            loader={<div className="loader"> Loading... </div>}
                            useWindow={false}
                          >
                            {notifications && notifications.length > 0 ?
                              notifications.map(element => (
                                <>
                                  <div className="row">
                                    <div style={{ textAlign: 'left' }} className="col-md-9" dangerouslySetInnerHTML={{ __html: element.message }} />
                                    <div className="col-md-3 time">
                                      {calculatTime(element)}
                                    </div>
                                  </div>
                                  <hr></hr>
                                </>
                              )) : ''}
                          </InfiniteScroll>{" "}
                        </div>{" "}
                      </div>
                    </div>
                  </> : ''}

                <div className=" d-sm-none  banner-head" style={{width:'100%', marginTop:'5px'/*marginRight:'90px',marginBottom:'-2px'*/}}>
                  <div>
                    <h1 className="text-uppercase m-0 mt-0" style={{fontSize:'20 !important'}}>zo organized</h1>
                    {/*<div style={{marginTop:'-13px', textAlign:'center'}}> <span className="text-secondary" >Life. Simplified.</span></div>*/}
                  </div>
                </div>
                {/* dropdown */}
                <div className="nav-link user-link d-md-none" style={{marginRight:'25px'}}>
                  <i className="fa fa-search"></i>
                  <div className="user-popup"
                    style={{ width: "200px" }}>
                    <div className="user-holder">
                      <form className="search-form" action="/search" onSubmit={(e) => handleSearchSubmit(e)}>
                        <FormItem className="navigation-search">
                          {getFieldDecorator('find', {
                              rules: [{ required: true, message:''}],
                          })(
                              <Input type="search" name="find" className="form-control border-0" placeholder="SEARCH..."  style={{width:'100%'}}/>
                          )}
                        </FormItem>
                        <button type="submit">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="nav-link user-link mr-md-0 mr-sm-100 user-header-icon">
                  <i className="fa fa-user"></i>
                  <div className="user-popup">
                    <div className="user-holder">
                      <ul className="user-list text-center">

                        {authContext.checkAuthentication() ?
                          <>
                            <li>
                              <Link to={"/profile"} style={{ 'cursor': 'pointer' }}>Profile</Link>
                            </li>
                            <li className="nav-item" style={{ 'cursor': 'pointer' }}>
                              <a className="nav-link" onClick={() => NavigateToAdminPanel()}>
                                <i
                                  className="fa fa-bullseye"
                                  aria-hidden="true"
                                ></i>{" "}
                              Portal
                            </a>
                            </li>
                          </> : ''}
                        <li>
                          {!authContext.checkAuthentication() ? <Link to={"/login"}>Login</Link> : <div onClick={() => logout()} style={{ color: 'black', 'cursor': 'pointer' }}>Logout</div>}
                        </li>
                        {authContext.checkAuthentication() ? '' : <li>
                          <Link to={"/register"} style={{ 'cursor': 'pointer' }}>Sign up</Link>
                        </li>}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="nav-link cart-link  ml-md-auto mr-md-50 subtopec cart-icon"  style={{ marginLeft: '27px'}}>
                  <Link to={"/view-cart"}>  
                    <Badge  count={state && state.addedItems && state.addedItems.reduce((accumulator, current) => accumulator + current.quantity, 0)} showZero={false}>
                      <i className="fa fa-shopping-cart text-light text-light1"></i> 
                    </Badge>
                  </Link>
                  <div className="cart-popup">
                    <div className="cart-holder">
                      <a className="close-btn d-none" href="#">
                        <i className="fa fa-close"></i>
                      </a>
                      <h2 className="text-left">SHOPPING CART</h2>

                      {state.addedItems && state.addedItems.map((item) => (
                        <ul key={item._id} className="cart-list">
                          <li>
                            <figure>
                              <img src={fetchSingleFileSrc(item)} alt="zo" style={{ width: "30px" }} />
                            </figure>
                          </li>
                          <li>
                            <span className="name">{item.title}</span>
                          </li>
                          {item.downloadable == false ? 
                          <li>
                            <span className="qty-title">
                              <i className="fa fa-minus-circle" onClick={() => subtractQuantity(item._id)}></i>
                           &nbsp;
                       {item.quantity}
                       &nbsp;
                         <i className="fa fa-plus-circle" onClick={() => addQuantity(item._id)}></i>
                            </span>
                          </li> : '1'
                          }
                          <li className="total">
                            <span className="total-price">${(item.price * item.quantity).toFixed(2)}</span>
                          </li>
                        </ul>
                      ))}


                      <div className="cart-footer">
                        {state.addedItems && state.addedItems.length > 0 ?
                          <>
                            <span className="total-amount">TOTAL: <span className="amount">${state.total?state.total.toFixed(2):''}</span></span>
                            <p>Taxes and shipping will be calculated at checkout</p>
                            <div className="btn-holder">
                              <Link to={"/view-cart"} className="btn btn-outline">VIEW CART</Link>
                              <Link to={'/checkout'} className="btn">CHECK OUT</Link>
                            </div>
                          </> : <p>NO ITEMS ADDED IN THE CART</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>


              



              </nav>
              {/*<div className="d-none d-lg-block hide_on_mobile">*/}
                <TopicsNavigation />
              {/*</div>*/}

            </div>
          </div>
        </div>
      </header>
    </Affix>
  );
};
const NavigationForm = Form.create()(Navigation);
 export default withRouter(NavigationForm);
