export const PERMISSIONS = {
    USER_PERMISSION: 'user_permission',
    BLOGS: 'systems/home',
    PRODUCTS: 'products',
    EMAILTEMPLATE: 'emailtemplate/home',
    WEBTEMPLATE: 'webtemplate/home',
    PROFILE: 'profile',
    ROLES: 'roles',
    USERS: 'users',
    PAGE_PREFERENCES: 'pagePrefrences',
    DASHBOARD: 'dashboard',
    ORDERS: 'orders'
}
