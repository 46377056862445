import React, { Component, useEffect, useState } from 'react';
import { LineChart, Sector, Line, XAxis, YAxis , ResponsiveContainer, Legend , Tooltip , ComposedChart , Area , Bar ,PieChart , Pie, Cell } from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from "./adminDashboard.module.scss";
import {Table , Layout, Select} from "antd";
import SideMenu from "../../SideMenu/SideMenu";
import AdminLayout from '../../../shared/layouts/admin';
import { Loading } from '../../../shared/modules/Loading/index';
import { getOrdersAmountByDate, getOrderCountByStatus, getOrderList,
    getRecentDepositsAmount } from "./service";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import Moment from 'react-moment';
import { Link } from 'react-router-dom'
const { Content, Sider } = Layout;
const Option = Select.Option;
const CustomTooltipContent = props => {
  if (props.payload[0] != null) {
    const newPayload = [
      {
        name: 'Total',
        value: props.payload[0].payload.total,
        // you can also add "unit" here if you need it
      }
    ];

    // we render the default, but with our overridden payload
    return <DefaultTooltipContent {...props} payload={newPayload} />;
  }

  // we just render the default
  return <DefaultTooltipContent {...props} />;
};

const Chart = ({data}) => {
        return (
            <ResponsiveContainer width="100%" height={300}>
                <ComposedChart width={730} height={250} data={data}>
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip content={<CustomTooltipContent />} />
                    <Area type="monotone" dataKey="total" fill="#B2968A" stroke="#8884d8" />
                    <Bar dataKey="total" barSize={7} fill="#3E5569" />
                    <Line type="monotone" dataKey="total" stroke="#ff7300" />
                </ComposedChart>
            </ResponsiveContainer>
    )
}

const Deposits = ({recentDeposits}) => {
        return (
        <React.Fragment>
            <div className={'shadow bg-white p-2 text-center rounded border border-light'}>
            <span className={`${styles.depositsHeading}`}>Recent Deposits</span>
            <h2 component="p" variant="h4">
                ${recentDeposits}
            </h2>
            <p color="textSecondary" >
                {/* on 15 March, 2019 */}
                <Moment format="D MMM YYYY">
                        {new Date()}
                    </Moment>
            </p>
            </div>
            <div className={'shadow bg-white p-4 text-center rounded border border-light mt-3'}>
            <span className={`${styles.depositsHeading}`}>Total Deposits</span>
            <h2 component="p" variant="h4">
                $440,475,9
            </h2>
            </div>
        </React.Fragment>
    )
}

const dataSource = [
    {
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
    },
    {
        key: '2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
    },
];

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
    },
];

const ListItems = ({data}) => {
    const columns = [
        {
            title: 'Date',
            //dataIndex: 'orderDate',
            key: 'orderDate',
            render: (text, record) => (
                <Moment format="D MMM YYYY">
                    {record.orderDate}
                </Moment>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'price',
            key: 'price',
            // render: text => <a>{text}</a>,
        },
        // {
        //     title: 'Sales Tax',
        //     dataIndex: 'salesTax',
        //     key: 'salesTax',
        //     // render: text => <a>{text}</a>,
        // },
        // {
        //     title: 'Shipping Price',
        //     dataIndex: 'shippingPrice',
        //     key: 'shippingPrice',
        //     // render: text => <a>{text}</a>,
        // },
        // {
        //     title: 'Grand Total',
        //     dataIndex: 'total',
        //     key: 'total',
        //     // render: text => <a>{text}</a>,
        // },
        {
            title: 'No Of Products',
            dataIndex: 'totalProducts',
            key: 'totalProducts',
            // render: text => <a>{text}</a>,
        },
        {
            title: 'Status',
            //dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <>
                {record.canceled == 2 &&
                    <div className='btn btn-info'>Request for cancel order</div>
                }
                {record.canceled == 1 &&
                    <div className='btn btn-warning'>Canceled</div>
                }
                {record.isRefunded == true && 
                    <div className='btn btn-danger'>Refunded</div>
                }
                {(record.isRefunded == false && !record.canceled) && 
                    record.status
                }
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span>
                    <Link to={`/admin/ordersdetail/${record._id}`}>View Detail</Link>
                </span>
            ),
            fixed: 'right'
        },
    ];
    function handleStatusChange(value, record) {
        console.log(value, "Record", record);
    }
    return (
            <div className='shadow mt-1 bg-white rounded'>
    <Table dataSource={data} columns={columns} scroll={{x: 600}}/>
                </div>
    )
}

const Orderdata = [
        {orders: 2, name: "Delivered"},
        {orders: 37, name: null},
        {orders: 3, name: "Pending"},
    ]

const colors = ['red', 'green','blue'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
        fill, payload, percent, value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{` ${value} Orders`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

class OrderDetails extends Component {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/hqnrgxpj/';

    state = {
        activeIndex: 0,
    };

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };
    
    render() {
        return (
            <ResponsiveContainer width="100%" height={297}>
            <PieChart width={600} height={297}>
                <Pie
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    data={this.props.data}
                    cx={"50%"}
                    cy={"50%"}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="orders"
                    onMouseEnter={this.onPieEnter}
                />
            </PieChart>
            </ResponsiveContainer>
        )
    }
}

const AdminDashboard = () => {
    const [state, setState] = useState({
        isLoading: true,
        ordersData: [],
        ordersList: [],
        orderCountsByStatus: [],
        recentDepositsAmount: 0
    });

    useEffect(() => {
        async function fetchData() {
            const { data } = await getOrdersAmountByDate();
            const orderList  = await getOrderList();
            const countsData = await getOrderCountByStatus();
            const recentDepositsData = await getRecentDepositsAmount();
            setState({ ...state, isLoading: false,
                ordersData: data,
                ordersList: orderList.data,
                orderCountsByStatus: countsData.data,
                recentDepositsAmount: recentDepositsData.data.recentDeposits.toLocaleString(undefined, {maximumFractionDigits:2})
            });
        }
        fetchData();
    }, []);

    return (
        <AdminLayout>
            { state.isLoading ? <Loading/> : 
        <Layout>
        <SideMenu selectedKey={'9'} />
        <Layout>
        <div className={`${styles.container} container-fluid`}>
            <div className={`shadow p-3 py-5 mb-2 mt-3 bg-white rounded`}>
                <Chart data={state.ordersData} />
            </div>
            <div className={`row py-3`}>
                <div className={`col-md-7`}>
                    <div className={`shadow bg-white text-center rounded border border-light`}>
                    <OrderDetails data={state.orderCountsByStatus} />
                    </div>
                    </div>
                <div className={'col'}>
                        <Deposits recentDeposits={state.recentDepositsAmount} />
                </div>
            </div>
            <div className={`mb-5`}>
                <h>Orders List</h>
            <ListItems data={state.ordersList} />
            </div>
        </div>
        </Layout>
        </Layout>
       }
       </AdminLayout>
    )
}

export default AdminDashboard;
