import { getData, deleteData, postData, putData } from './../../apis/request';

export async function GetPrefrences(email) {
    try {
        const data = await getData(`subscriber/prefrences/${email}`);
        return data;
    }
    catch (err) {
        return err;
    }
}

export async function UpdatePrefrences(body) {
    try {
        const data = await putData(`subscriber/`, body);

        return data;
    }
    catch (err) {
        return err;
    }
}


// export async function GetTemplateById(productId) {
//     try {
//         const data = await getData(`emailtemplate/${productId}`);
//         return data;
//     }
//     catch (err) {
//     }
// }

// export async function deleteTemplate(productId) {
//     const data = await deleteData(`emailtemplate/${productId}`);
//     return data;
// }

// export async function addTemplate(body) {
//     const { data } = await postData('emailtemplate', body);
//     return data;
// }
// export async function updateTemplate(body) {
//     const { data } = await putData('emailtemplate', body);
//     return data;
//}
