import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Loading } from '../../shared/modules/Loading/index';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { Checkbox } from 'antd';
import styles from './unsubscribe.module.scss';
import { GetEmail } from './service';
import toastr from 'toastr';
import { GetPrefrences, UpdatePrefrences } from '../../containers/prefrences/services';
const Unsubscribe = (props) => {

    let url = props.location.pathname;
    let split = url.split("/");
    let token = split[2];

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [state, setState] = useState({
        isLoading: false,
        email: 'abc@yahoo.com'
    });
    const [prefrences, setPrefrences] = useState({
        newletter: true,
        productUpodates: true,
        notifications: true,
        anouncements: true,
    });
    useEffect(() => {
        async function fetchData() {
            setState({ ...state, isLoading: true });
            const res = await GetEmail(token);
            const { data } = await GetPrefrences(res.data.email);
            setPrefrences(data.prefrences);
            setState({ ...state, email: res.data.email, isLoading: false });
        }
        fetchData();
    }, []);

    async function updatePreferences() {
        let data = { email: state.email, prefrences: prefrences };
        const response = await UpdatePrefrences(data);
        if (response.status == 200) {
            toastr.success("Email prefrences updated successfully", "");
            //props.history.push('/unsubscription-success');
        }
    }

    const onChangeNewsLetter = e => {
        setPrefrences({
            ...prefrences,
            newletter: e.target.checked,
        });
    };
    const onChangeProductUpodates = e => {
        setPrefrences({
            ...prefrences,
            productUpodates: e.target.checked,
        });
    };
    const onChangeNotifications = e => {
        setPrefrences({
            ...prefrences,
            notifications: e.target.checked,
        });
    };
    const onChangeAnouncments = e => {
        setPrefrences({
            ...prefrences,
            anouncements: e.target.checked,
        });
    };

    return (
        <>
            <Container className={styles['unsubscription']}>
                <Row>
                    <Col md='3'>
                        <Link to={"/"} className="logo-holder">
                            <img
                                src='/images/logo2.png'
                                alt="Zo"
                                className="img-fluid"
                                style={{ width: "50px" }}
                            />
                        </Link>
                    </Col>
                    <Col md='6'>
                        <h1>Let's Keep in Touch</h1>
                        <p>After unsubscription, you won't receive any emails from us.</p>

                        <p className='text-muted' style={{ marginTop: '30px' }}>
                            Preferences for {state.email}
                        </p>
                        <div className={styles['list-preferences']}>
                            {state.isLoading ? <Loading></Loading> : <>
                                <div className="card">
                                    <div className="card-body" style={{ paddingLeft: '30px ', paddingRight: '30px' }}>
                                        <>
                                            <Checkbox
                                                checked={prefrences.newletter}
                                                onChange={onChangeNewsLetter}
                                            >
                                                News Letter
            </Checkbox>
                                        </>
                                        <div className='clearfix py-2'></div>
                                        <>
                                            <Checkbox
                                                checked={prefrences.productUpodates}
                                                onChange={onChangeProductUpodates}
                                            >
                                                Product Upodates
            </Checkbox>
                                        </>
                                        <div className='clearfix py-2'></div>
                                        <>
                                            <Checkbox
                                                checked={prefrences.notifications}
                                                onChange={onChangeNotifications}
                                            >
                                                Notifications
            </Checkbox>
                                        </>
                                        <div className='clearfix py-2'></div>
                                        <>
                                            <Checkbox
                                                checked={prefrences.anouncements}
                                                onChange={onChangeAnouncments}
                                            >
                                                Anouncments
            </Checkbox>
                                        </>
                                        <div className='clearfix py-2'></div>
                                    </div>
                                </div>
                            </>}

                        </div>
                        <Button onClick={handleShow} className={styles['btn-unsubscribe']}>Update my Preferences</Button>
                    </Col>
                </Row>
            </Container>

            <Modal
                className={styles['modal-subscription-confirmation']}
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body className='text-center'>
                    Are you sure you want to unsubscribe?
        <div className='mt-3'>
                        <Button variant="primary" className={styles['btn-done']}
                            onClick={() => updatePreferences()}>Unsubscribe</Button>
                        <Button variant="secondary" className={styles['btn-close']} onClick={handleClose}>
                            Close
        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default withRouter(Unsubscribe);